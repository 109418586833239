import { BlueCheckIcon, GreyXIcon, HelpCircleIcon, } from "../resources/icons";
import { AUSTRALIA, SINGAPORE, GERMANY, IRELAND, UNITED_KINGDOM, INDIA } from "./constants";

// ---------------------- Subscription Constants --------------------------
class Content {
  constructor(label, helpIcon, helpDesc, included, excluded) {
    this.label = label; //use this as the title of the content
    this.helpIcon = helpIcon; //use this as the tooltip help icon
    this.helpDesc = helpDesc; //use this as the tooltip help description
    this.included = included; //use this as the component for INCLUDED
    this.excluded = excluded; //use this as the component for EXCLUDED

  }
}

const tick = <BlueCheckIcon />;
const cross = <GreyXIcon />;
const helpIcon = <HelpCircleIcon />;

export const TIER_CONTENTS = [
  new Content(
    "RetinoScan",
    helpIcon,
    "RetinoScan analyses retinal images to accurately identify patients' with Diabetic Retinopathy, Glaucoma and/or Age Related Macular Degeneration.",
    tick,
    cross),

  new Content(
    "RetinoScan Advanced",
    helpIcon,
    "For patients with a disease diagnosis, RetinoScan Advanced determines the disease severity and provide lesion monitoring services.",
    "Suspected Cases",
    cross),

  new Content(
    "Review by Eye Professional",
    null,
    " ",
    "Suspected Cases",
    cross),

  new Content(
    "Comprehensive Patient Report",
    null,
    " ",
    tick,
    cross),

  new Content(
    "Class II Medical Device",
    helpIcon,
    "Clinically Validated",
    tick,
    cross),

  new Content("Cloud Storage",
    null,
    " ",
    tick,
    cross),

  new Content(
    "Patient Data Retention",
    helpIcon,
    "In accordance to HIPPA compliances, all patients' data will be stored and backed up for 15 years",
    "15 years",
    cross),

  new Content(
    "Referral Pathway",
    null,
    " ",
    "Coming Soon",
    "Coming Soon"),
]

const INCLUDED = 1
const EXCLUDED = 0


export const BASIC = [
  INCLUDED,
  EXCLUDED,
  EXCLUDED,
  INCLUDED,
  INCLUDED,
  INCLUDED,
  INCLUDED,
  INCLUDED
]

export const STANDARD = [
  INCLUDED,
  EXCLUDED,
  INCLUDED,
  INCLUDED,
  INCLUDED,
  INCLUDED,
  INCLUDED,
  INCLUDED
]

export const PREMIUM = [
  INCLUDED,
  INCLUDED,
  INCLUDED,
  INCLUDED,
  INCLUDED,
  INCLUDED,
  INCLUDED,
  INCLUDED
]

export const TIER_ORDER = [
  "Basic", "Standard", "Premium"
]

export const SUBSCRIPTION_INCLUSIONS_AU = {
  "REGION": AUSTRALIA,
  "BASIC": BASIC,
  "STANDARD": STANDARD,
  "PREMIUM": PREMIUM
}

export const SUBSCRIPTION_INCLUSIONS_DE = {
  "REGION": GERMANY,
  "BASIC": BASIC,
  "STANDARD": STANDARD,
  "PREMIUM": PREMIUM
}

export const SUBSCRIPTION_INCLUSIONS_SG = {
  "REGION": SINGAPORE,
  //"BASIC": BASIC,
  "STANDARD": STANDARD,
  "PREMIUM": PREMIUM
}

export const SUBSCRIPTION_INCLUSIONS_IE = {
  "REGION": IRELAND,
  "BASIC": BASIC,
  "STANDARD": STANDARD,
  "PREMIUM": PREMIUM
}

export const SUBSCRIPTION_INCLUSIONS_GB = {
  "REGION": UNITED_KINGDOM,
  "BASIC": BASIC,
  "STANDARD": STANDARD,
  "PREMIUM": PREMIUM
}

export const SUBSCRIPTION_INCLUSIONS_IN = {
  "REGION": INDIA,
  "BASIC": BASIC,
  "STANDARD": STANDARD,
  "PREMIUM": PREMIUM
}

export const MONTHLY_BILLED = 1
export const ANNUALLY_BILLED = 2

export const Permission = {
  CAN_SELECT_DR_RISK: "select:dr_risk",
  CAN_SELECT_LITE: "select:lite",
  CAN_SELECT_FULL: "select:full",
  CAN_SELECT_AUDITOR: "select:auditor"
}

let permissionValues = Object.values(Permission);

export const BASIC_PERMISSIONS = permissionValues.slice(0, -2)
//export const BASIC_PLUS_PERMISSIONS = permissionValues.filter(permission => !permission.includes("full"))
export const STANDARD_PERMISSIONS = [...permissionValues.slice(0, -2), ...permissionValues.slice(-1)]
//export const STANDARD_PLUS_PERMISSIONS = permissionValues
export const PREMIUM_PERMISSIONS = permissionValues
