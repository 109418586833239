import axios from 'axios';
import { getBackendURL } from './utils/urls';

// const axiosInstance = axios.create({
//     baseURL: `${window.location.origin.toString()}/api/`,
//     timeout: 5000,
//     headers: {
//         'Authorization': "JWT " + localStorage.getItem('access_token'),
//         'Content-Type': 'application/json',
//         'accept': 'application/json'
//     }
// });

axios.defaults.adapter = require("axios/lib/adapters/http")

const axiosInstance = axios.create({
	baseURL: `${getBackendURL()}/api/`,
	timeout: 60000,
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json',
		accept: 'application/json',
	},
	xsrfCookieName: 'csrftoken',
	xsrfHeaderName: 'X-CSRFToken', // default
});

const axiosFileUploadInstance = axios.create({
	baseURL: `${getBackendURL()}/api/`,
	timeout: 60000,
	withCredentials: true,
	headers: {
		'Content-Type': 'multipart/form-data',
		accept: 'application/json',
	},
	xsrfCookieName: 'csrftoken',
	xsrfHeaderName: 'X-CSRFToken', // default
});

const simplyBookAPIConfig = {
	headers: {
		'X-Token': '',
		'X-Company-Login': 'telemedc',
	},
	refreshToken: '',
};

export const axiosMeetingInstance = axios.create({
	baseURL: 'https://user-api-v2.simplybook.me/admin',
	timeout: 60000,
	headers: simplyBookAPIConfig.headers,
});

// this is to redirect user to the login page if user is not authenticated
axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		return new Promise((resolve, reject) => {
			if (error.response && error.response.status === 401) {
				if (!window.location.pathname.match('login')) {
					window.location.href = '/login/';
				}
			}
			reject(error);
		});
	}
);

const resendSimplyBookAPIRequest = (originalRequest, authenticationData) => {
	axiosMeetingInstance.defaults.headers['X-Token'] = authenticationData.token;
	simplyBookAPIConfig.headers['X-Token'] = authenticationData.token;
	simplyBookAPIConfig.refreshToken = authenticationData.refresh_token;
	const request = {
		...originalRequest,
		headers: { ...originalRequest.headers, ...simplyBookAPIConfig.headers },
	};
	return axios(request);
};

axiosMeetingInstance.interceptors.request.use(async (request) => {
	// get access token if empty
	if (!axiosMeetingInstance.defaults.headers['X-Token']) {
		try {
			const response = await axiosInstance.get('/scheduling/simply_book/auth/get_tokens');
			axiosMeetingInstance.defaults.headers['X-Token'] = response.data.token;
			simplyBookAPIConfig.refreshToken = response.data.refresh_token;
			simplyBookAPIConfig.headers['X-Token'] = response.data.token;
			return { ...request, headers: { ...request.headers, ...simplyBookAPIConfig.headers } };
		} catch (e) {
			return request;
		}
	}
	return request;
});

axiosMeetingInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		return new Promise((resolve, reject) => {
			const originalRequest = error.config;
			if (error.response && error.response.status === 419 && simplyBookAPIConfig.refreshToken) {
				const response = axios
					.post('https://user-api-v2.simplybook.me/admin/auth/refresh-token', {
						company: simplyBookAPIConfig.headers['X-Company-Login'],
						refresh_token: simplyBookAPIConfig.refreshToken,
					})
					.then((response) => {
						resendSimplyBookAPIRequest(originalRequest, response.data);
					});
				resolve(response);
			} else if (error.response && error.response.status === 401) {
				const response = axiosInstance.get(`/scheduling/simply_book/auth/get_tokens`).then((res) => {
					resolve(response);
					return resendSimplyBookAPIRequest(originalRequest, res.data);
				}).catch((err) => {
					reject(err);
				})}
			reject(error);
		});
	}
);

// axiosInstance.interceptors.response.use(
//     response => response,
//     error => {
//       const originalRequest = error.config;

//       if (error.response.status === 401 && error.response.statusText === "Unauthorized") {
//           const refresh_token = localStorage.getItem('refresh_token');

//           return axiosInstance
//               .post('/token/refresh/', {refresh: refresh_token})
//               .then((response) => {

//                   localStorage.setItem('access_token', response.data.access);
//                   localStorage.setItem('refresh_token', response.data.refresh);

//                   axiosInstance.defaults.headers['Authorization'] = "JWT " + response.data.access;
//                   originalRequest.headers['Authorization'] = "JWT " + response.data.access;

//                   return axiosInstance(originalRequest);
//               })
//               .catch(error => {
//                   console.log(error)
//               });
//       }
//       return Promise.reject(error);
//   }
// );

export default axiosInstance;
