import React from "react";
import { styled } from '@mui/material/styles';
import { Button } from "@mui/material";
import Loading from "../organisms/Loading/Loading";

const StyledButton = styled(Button, {
  shouldForwardProp(propName) {
    return !["showLoading", "noMargin", "textButton"].includes(propName);
  }
})((
  {
    theme, showLoading, noMargin, textButton,
  }
) => ({
  margin: noMargin ? 0 : theme.spacing(1),
  padding: showLoading ? 0 : theme.spacing(2),
  textTransform: "capitalize",
  fontWeight: "700",
  boxShadow: theme.shadows[0],
  minWidth: "25ch",
  borderRadius: theme.shape.borderRadiusSmall,
  minHeight: "56.5px",
  zIndex: "200",
  color: "white",


  '&:hover': {
    backgroundColor: textButton && "hsla(120, 100%, 50%, 0)",
    color: textButton && theme.palette.primary.main,
    boxShadow: textButton && "none"
  },
}))

// [`& .${classes.warning}`]: {
//   backgroundColor: 'rgba(255,110,94,0.9)',
//   color: 'white',
//   width: '140px',
//   '&:hover': {
//     backgroundColor: '#FF6E5E',
//   },
// }


const LargeButton = ({
  color,
  className,
  onClick,
  showLoading,
  textButton,
  noMargin,
  style,
  ...otherProps
}) => {


  return (
    <StyledButton
      {...otherProps}
      showLoading={showLoading}
      noMargin={noMargin}
      textButton={textButton}
      variant="contained"
      onClick={onClick}
      color={
        color === "blue" ? "primary" : "secondary"
      }
      endIcon={showLoading ? null : otherProps.endIcon}
      startIcon={showLoading ? null : otherProps.startIcon}
      disabled={showLoading || otherProps.disabled}
      style={style}
    >
      {showLoading ? <Loading size="sm" /> : otherProps.children}
    </StyledButton>
  );
};

export default LargeButton;
