import React from "react";
import {
	PATIENTLOOKUP_CLOSE,
	PATIENTLOOKUP_OPEN,
	FILTER_CLOSE,
	FILTER_OPEN,
	WEBSOCKET_CONNECTED_EXAMS,
	WEBSOCKET_DISCONNECTED_EXAMS,
	STDR_RISK_PARAM,
	DR_RISK_PARAM,
	SET_SNACKBAR_SUCCESS,
	SET_SNACKBAR_WARNING,
	SET_SNACKBAR_INFO,
	SNACKBAR_CLOSE,
	SET_SNACKBAR_ERROR,
	ADDSERVICES_OPEN,
	ADDSERVICES_CLOSE,
	IMAGESVIEW_CLOSE,
	MODAL_OPEN,
	MODAL_CLOSE,
	IMAGESVIEW_OPEN,
	WEBSOCKET_CONNECTED_AUDIT_POOL,
	WEBSOCKET_DISCONNECTED_AUDIT_POOL,
	OPEN_TERMS_DIALOG,
	CLOSE_TERMS_DIALOG,
	SET_UNAGREED_TERMS_BY_USER_ROLE,
	SET_GOOGLE_PLACES,
	SET_COUNTRY,
	SET_EMAIL_LIST,
	COLLAPSE_NAV_BAR_LEFT,
	SET_CURRENT_SCREEN,
} from "../actions/types.js";

const initialState = {
	patientLookUpOpen: false,
	filterTableOpen: false,
	addServicesOpen: false,
	current_exam_websockets: {},
	current_audit_pool_websocket: null,
	message: "",
	snackBarOpen: false,
	modalOpen: [], // array of modal(s) to be opened
	imagesView: {
		visible: false,
		image: "",
	},
	unagreedTermsByUserRole: null,
	openTermsDialog: false,
	openOrganizationChangeDialog: false,
	email_list: [],
	nav_bar_left_collapse: false,
	current_screen: ''
};

export default function controlsFunction(state = initialState, action) {
	switch (action.type) {
		case PATIENTLOOKUP_CLOSE:
			return {
				...state,
				patientLookUpOpen: false,
			};
		case PATIENTLOOKUP_OPEN:
			return {
				...state,
				patientLookUpOpen: true,
			};
		case FILTER_CLOSE:
			return {
				...state,
				filterTableOpen: false,
			};
		case FILTER_OPEN:
			return {
				...state,
				filterTableOpen: true,
			};
		// Reducers for add risk prediction services pop up
		case ADDSERVICES_CLOSE:
			return {
				...state,
				addServicesOpen: false,
			};
		case ADDSERVICES_OPEN:
			return {
				...state,
				addServicesOpen: true,
			};
		case MODAL_CLOSE:
			const modals = [...state.modalOpen];
			if (state.modalOpen.indexOf(action.payload) !== -1)
				modals.splice(state.modalOpen.indexOf(action.payload), 1);
			return {
				...state,
				modalOpen: modals,
			};
		case MODAL_OPEN:
			return {
				...state,
				modalOpen: [...state.modalOpen, action.payload],
			};
		case WEBSOCKET_CONNECTED_EXAMS:
			return {
				...state,
				current_exam_websockets: action.payload,
			};
		case WEBSOCKET_DISCONNECTED_EXAMS:
			return {
				...state,
			};
		case SNACKBAR_CLOSE:
			return {
				...state,
				snackBarOpen: false,
			};
		// Reducers for enabling and disabling risk prediction services
		case DR_RISK_PARAM:
			return {
				...state,
				disableDR: action.payload["Error"],
				messageDR: action.payload,
			};
		case STDR_RISK_PARAM:
			return {
				...state,
				disableSTDR: action.payload["Error"],
				messageSTDR: action.payload,
			};
		case SET_SNACKBAR_SUCCESS:
			return {
				...state,
				snackBarOpen: true,
				message: action.payload,
				type: "success",
			};
		case SET_SNACKBAR_INFO:
			return {
				...state,
				snackBarOpen: true,
				message: action.payload,
				type: "info",
			};
		case SET_SNACKBAR_WARNING:
			return {
				...state,
				snackBarOpen: true,
				message: action.payload,
				type: "warning",
			};
		case SET_SNACKBAR_ERROR:
			//print(action)
			return {
				...state,
				snackBarOpen: true,
				message: action.payload,
				type: "error",
			};
		case IMAGESVIEW_CLOSE:
			return {
				...state,
				imagesView: {
					visible: false,
					image: "",
				},
			};
		case IMAGESVIEW_OPEN:
			return {
				...state,
				imagesView: {
					visible: true,
					image: action.payload,
				},
			};
		case WEBSOCKET_CONNECTED_AUDIT_POOL:
			return {
				...state,
				current_audit_pool_websocket: action.payload,
			};
		case WEBSOCKET_DISCONNECTED_AUDIT_POOL:
			return {
				...state,
				current_audit_pool_websocket: null,
			};
		case OPEN_TERMS_DIALOG:
			return {
				...state,
				openTermsDialog: true,
			};
		case CLOSE_TERMS_DIALOG:
			return {
				...state,
				openTermsDialog: false,
			};
		case SET_UNAGREED_TERMS_BY_USER_ROLE:
			return {
				...state,
				unagreedTermsByUserRole: action.payload,
			};
		case SET_GOOGLE_PLACES:
			return {
				...state,
				google_address: action.payload,
			};
		case SET_COUNTRY:
			return {
				...state,
				country: action.payload,
			};
		case SET_EMAIL_LIST:
			return {
				...state,
				email_list: action.payload
			};
		case COLLAPSE_NAV_BAR_LEFT:
			return {
				...state,
				nav_bar_left_collapse: action.payload
			};
			case SET_CURRENT_SCREEN:
				return {
					...state,
					current_screen: action.payload
				};
		default:
			return state;
	}
}
