import { TELECONSULT_SYSTEM_TIMEZONE } from '../constants/meetings';

const { zonedTimeToUtc, utcToZonedTime, format } = require('date-fns-tz');

const timeUnitMapToMillisecond = {
	millisecond: 1,
	ms: 1,
	second: 1000,
	sec: 1000,
	minute: 60000,
	min: 60000,
	hour: 3600000,
	h: 3600000,
};

export const getDateFromAndTo = (date, interval) => {
	const dateTo = new Date(+date.valueOf());
	dateTo.setDate(dateTo.getDate() + interval);
	return { dateFrom: formatDateToISOString(date), dateTo: formatDateToISOString(dateTo) };
};

/**
 * covert date to any specified timezone
 * @param {string | number | Date} date the date that need to be formatted to specific timezone
 * @param {string} toTimezone The time zone that date is formatted to
 * @param {string} fromTimezone The original time zone of the date
 */
export const convertDateToZonedTime = (
	date,
	toTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
	fromTimezone = TELECONSULT_SYSTEM_TIMEZONE // scheduling system timezone
) => {
	return utcToZonedTime(zonedTimeToUtc(date, fromTimezone), toTimezone);
};

export const formatDateToISOString = (date) => {
	// format date to YYYY-MM-DD
	// return `${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2,'0')}-${(date.getDate()).toString().padStart(2,'0')}`
	return format(date, 'yyyy-MM-dd');
};

export const convertToLocaleDateString = (dateString) => {
	const date = new Date(Date.parse(dateString));
	return format(date, 'dd/MM/yyyy, HH:mm');
};

export const addMinutesToDate = (date, minutes = 0) => {
	date.setMinutes(date.getMinutes() + minutes);
	return date;
};

export const calculateTimeMinuteDiff = (date1, date2) => {
	return Math.ceil((date1.getTime() - date2.getTime()) / (1000 * 60));
};

/**
 * calculate time difference in milliseconds or seconds or minutes or hours between two dates
 * @param {Date} startDate start date
 * @param {Date} endDate end date
 * @param {'millisecond' | 'ms' | 'seconds' | 'sec' | 'minute' | 'min' | 'hour' | 'h'} unit time unit of the time difference returned
 * @param {'ceil' | 'floor' | 'round'} roundType the types of rounding time difference
 */
export const calculateTimeDiff = (startDate, endDate, unit = 'millisecond', roundType = undefined) => {
	switch (roundType) {
		case 'ceil':
			return Math.ceil((startDate.getTime() - endDate.getTime()) / timeUnitMapToMillisecond[unit]);
		case 'floor':
			return Math.floor((startDate.getTime() - endDate.getTime()) / timeUnitMapToMillisecond[unit]);
		case 'round':
			return Math.round((startDate.getTime() - endDate.getTime()) / timeUnitMapToMillisecond[unit]);
		default:
			return (startDate.getTime() - endDate.getTime()) / timeUnitMapToMillisecond[unit];
	}
};
