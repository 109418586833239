export const GET_PATIENTS = 'GET_PATIENTS';
export const GET_PATIENT_DETAILS = 'GET_PATIENT_DETAILS';
export const CREATE_PATIENT = 'CREATE_PATIENT';
export const GET_PATIENT_EXAMS = 'GET_PATIENT_EXAMS';
export const GET_PATIENT_RISK_SERVICES = 'GET_PATIENT_RISK_SERVICES'
export const GET_EXAMS_BY_OPERATOR = 'GET_EXAMS_BY_OPERATOR';
export const GET_EXISTING_EXAMS_BY_OPERATOR = 'GET_EXISTING_EXAMS_BY_OPERATOR';
export const GET_PATIENT_MED_HISTORY = 'GET_PATIENT_MED_HISTORY';
export const UPDATE_PATIENT_MED_HISTORY = 'UPDATE_PATIENT_MED_HISTORY';
export const UPDATE_PATIENT_EXAMS = 'UPDATE_PATIENT_EXAMS';
export const UPDATE_PATIENT_CONTACT_DETAILS = 'UPDATE_PATIENT_CONTACT_DETAILS';
export const UPDATE_PATIENT_DEMOGRAPHIC_DETAILS = 'UPDATE_PATIENT_DEMOGRAPHIC_DETAILS';
export const UPDATE_EXAM_STATUS= 'UPDATE_EXAM_STATUS';
export const REFRESH_EXAM_DETAILS= 'REFRESH_EXAM_DETAILS';
export const GET_URGENT_EXAMS='GET_URGENT_EXAMS'
export const SET_EXAM_PAGINATION_INFO = 'SET_EXAM_PAGINATION_INFO'

export const UPDATE_ORDER_DETAILS='UPDATE_ORDER_DETAILS';

export const REPORT_DOWNLOADED = 'REPORT_DOWNLOADED';

export const GET_NUMBER_OF_EXAM_PAGES = 'GET_NUMBER_OF_EXAM_PAGES';

export const CREATE_EXAM = 'CREATE_EXAM';
export const GET_EXAM_DETAILS = 'GET_EXAM_DETAILS';
export const GET_EXAM_DETAILS_NO_REFRESH_IMAGES = 'GET_EXAM_DETAILS_NO_REFRESH_IMAGES'
export const GET_IMAGE_DETAILS = 'GET_IMAGE_DETAILS';
export const CLEAR_EXAM_DETAILS = 'CLEAR_EXAM_DETAILS';
export const FORM_FIELDS_ADDED = 'FORM_FIELDS_ADDED';

export const IMAGE_UPLOADING = 'IMAGE_UPLOADING';
export const IMAGE_UPLOADED = 'IMAGE_UPLOADED';
export const ASSIGN_LATERALITY = 'ASSIGN_LATERALITY';
export const IMAGE_DELETED = 'IMAGE_DELETED';
export const IMAGES_SUBMITTED = 'IMAGES_SUBMITTED';
export const IMAGE_GRADED = 'IMAGE_GRADED';
export const GRADING_SUBMITTED = 'GRADING_SUBMITTED';
export const SERVICE_RESUBMITTED = 'SERVICE_RESUBMITTED';


export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const ACCOUNT_ACTIVATED = 'ACCOUNT_ACTIVATED';
export const ACCOUNT_ACTIVATION_ERROR = 'ACCOUNT_ACTIVATION_ERROR';
export const TERMS_RECEIVED = 'TERMS_RECEIVED';
export const UNAGREED_TERMS_RECEIVED = 'UNAGREED_TERMS_RECEIVED';
export const ACCEPTED_TERMS = 'ACCEPTED_TERMS';
export const LIST_USERS_TO_APPROVE = 'LIST_USERS_TO_APPROVE';
export const LIST_ORGANISATIONS = 'LIST_ORGANISATIONS';
export const ORGANISATION_ADDED = 'ORGANISATION_ADDED';
export const USER_APPROVED = 'USER_APPROVED';
export const FREE_CREDITS_ADDED = 'FREE_CREDITS_ADDED';
export const LIST_USERS = 'LIST_USERS';
export const LIST_AUDITORS = 'LIST_AUDITORS';
export const GET_AUDIT_ACCESS_TYPE = 'GET_AUDIT_ACCESS_TYPE ';


export const SET_EMAIL_LIST = 'SET_EMAIL_LIST';
export const INVITATION_SEND = 'INVITATION_SEND';
export const INVITATION_CHECK = 'INVITATION_CHECK';
export const INVITATION_RESPONSE = 'INVITATION_RESPONSE';

export const PATIENTLOOKUP_CLOSE = 'PATIENTLOOKUP_CLOSE';
export const PATIENTLOOKUP_OPEN = 'PATIENTLOOKUP_OPEN';
export const FILTER_CLOSE = 'FILTER_CLOSE';
export const FILTER_OPEN = 'FILTER_OPEN';
export const WEBSOCKET_CONNECTED_EXAMS = 'WEBSOCKET_CONNECTED';
export const WEBSOCKET_DISCONNECTED_EXAMS = 'WEBSOCKET_DISCONNECTED_EXAMS';
export const DR_RISK_PARAM = 'DR_RISK_PARAM';
export const STDR_RISK_PARAM = 'STDR_RISK_PARAM';
export const SET_SNACKBAR_SUCCESS = 'SET_SNACKBAR_SUCCESS';
export const SET_SNACKBAR_INFO = 'SET_SNACKBAR_INFO';
export const SET_SNACKBAR_WARNING = 'SET_SNACKBAR_WARNING';
export const SET_SNACKBAR_ERROR = 'SET_SNACKBAR_ERROR';
export const SNACKBAR_CLOSE = 'SNACKBAR_CLOSE';
export const IMAGESVIEW_CLOSE = 'IMAGESVIEW_CLOSE';
export const IMAGESVIEW_OPEN = 'IMAGESVIEW_OPEN'
export const ADDSERVICES_OPEN = 'ADDSERVICES_OPEN';
export const ADDSERVICES_CLOSE = 'ADDSERVICES_CLOSE';
export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';


export const OPEN_TERMS_DIALOG = 'OPEN_TERMS_DIALOG';
export const CLOSE_TERMS_DIALOG = 'CLOSE_TERMS_DIALOG';
export const SET_UNAGREED_TERMS_BY_USER_ROLE = 'SET_UNAGREED_TERMS_BY_USER_ROLE';

export const DISPLAY_ASSIGNED_AUDITS = 'DISPLAY_ASSIGNED_AUDITS';
export const DISPLAY_POOLED_AUDITS = 'DISPLAY_POOLED_AUDITS';
export const DISPLAY_PENDING_AUDITS = 'DISPLAY_PENDING_AUDITS';
export const DISPLAY_COMPLETED_AUDITS = 'DISPLAY_COMPLETED_AUDITS';
export const AUDIT_SUBMITTED = 'AUDIT_SUBMITTED';
export const GET_AUDIT_DETAILS = 'GET_AUDIT_DETAILS';
export const AUDIT_ASSIGN = 'AUDIT_ASSIGN';
export const AUDIT_UNASSIGN = 'AUDIT_UNASSIGN';
export const TOTAL_POOLED_AUDIT_COUNT = 'TOTAL_POOLED_AUDIT_COUNT'

export const WEBSOCKET_CONNECTED_AUDIT_POOL = 'WEBSOCKET_CONNECTED_AUDIT_POOL ';
export const WEBSOCKET_DISCONNECTED_AUDIT_POOL = 'WEBSOCKET_DISCONNECTED_AUDIT_POOL';
export const WS_AUDIT_STATUS_CHANGE = 'WS_AUDIT_STATUS_CHANGE';
export const SHOULD_REFRESH_POOLED_AUDIT_EXAMS = 'SHOULD_REFRESH_POOLED_AUDIT_EXAMS'
export const CONNECT_REVIEWING_AUDIT_WEBSOCKET= 'CONNECT_REVIEWING_AUDIT_WEBSOCKET'
export const DISCONNECT_REVIEWING_AUDIT_WEBSOCKET= 'DISCONNECT_REVIEWING_AUDIT_WEBSOCKET'


export const MOST_RECENT_HEALTH_DATA = 'MOST_RECENT_HEALTH_DATA';
export const UPDATE_HEALTH_DATA = 'UPDATE_HEALTH_DATA';

export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const GET_PRICE_GROUPS = 'GET_PRICE_GROUPS';
export const GET_CREDITS = 'GET_CREDITS';
export const GET_SERVICE_PRICES = 'GET_SERVICE_PRICES';
export const GET_PAST_PURCHASES = 'GET_PAST_PURCHASES';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';

export const SET_GOOGLE_PLACES = 'SET_GOOGLE_PLACES';
export const SET_COUNTRY= 'SET_COUNTRY';

export const GET_DASHBOARD_OVERVIEW = 'GET_DASHBOARD_OVERVIEW';
export const GET_OVERALL_COMPLETED_COUNT = 'GET_OVERALL_COMPLETED_COUNT';

export const GET_SUBSCRIPTION_PLANS = 'GET_SUBSCRIPTION_PLANS';
export const GET_PAST_SUBSCRIPTIONS = 'GET_PAST_SUBSCRIPTIONS';
export const SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS';
export const GET_CURRENT_SUBSCRIPTION = 'GET_CURRENT_SUBSCRIPTION';
export const SUBSCRIPTION_CANCELLED = 'SUBSCRIPTION_CANCELLED';
export const SUBSCRIPTION_UPDATED = 'SUBSCRIPTION_UPDATED';
export const SUBSCRIPTION_RESUMED = 'SUBSCRIPTION_RESUMED';
export const UPDATE_URL_RETRIEVED = 'UPDATE_URL_RETRIEVED';
export const ADD_ON_PURCHASED = 'ADD_ON_PURCHASED';

export const GET_MEETING_HOSTS = 'GET_MEETING_HOSTS';
export const GET_MEETINGS = 'GET_MEETINGS';
export const GET_UPCOMING_MEETINGS = 'GET_UPCOMING_MEETINGS';
export const GET_AVAILABLE_SLOTS = 'GET_AVAILABLE_SLOTS';
export const GET_FIRST_AVAILABLE_SLOT = 'GET_FIRST_AVAILABLE_SLOT';
export const GET_SCHEDULE = 'GET_SCHEDULE';
export const SELECT_MEETING_HOST = 'SELECT_MEETING_HOST';
export const SELECT_MEETING_TIME = 'SELECT_MEETING_TIME';
export const BOOK_NEW_MEETING = 'BOOK_NEW_MEETING';
export const CANCEL_MEETING = 'CANCEL_MEETING';
export const GET_MEETING_DETAILS = 'GET_MEETING_DETAILS';
export const GET_RESCHEDULE_MEETING_DETAILS = 'GET_RESCHEDULE_MEETING_DETAILS'
export const JOIN_MEETING = 'JOIN_MEETING';
export const GET_CLIENT_INFO = 'GET_CLIENT_INFO';
export const EDIT_CLIENT_INFO = 'EDIT_CLIENT_INFO';
export const SET_EXAM_FOR_MEETING = 'SET_EXAM_FOR_MEETING'
export const SET_TIMEZONE = 'SET_TIMEZONE'
export const SET_SB_CLIENT_ID = 'SET_SB_CLIENT_ID'

export const COLLAPSE_NAV_BAR_LEFT = 'COLLAPSE_NAV_BAR_LEFT'
export const SET_CURRENT_SCREEN = 'SET_CURRENT_SCREEN'