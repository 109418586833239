import React from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const StyledButton = styled(Button)((
  {
    theme, color, accent
  }
) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(2),
  boxShadow: theme.shadows[0],
  minWidth: color === "primaryLight" ? "35ch" : "25ch",
  borderRadius: theme.shape.borderRadiusSmall,
  zIndex: "200",
  minHeight: "56.5px",
  textTransform: 'capitalize',
  fontWeight: accent ? 'bold' : 700,

  backgroundColor: color === "primaryLight" ? theme.palette.primary.light :
    (accent ? theme.palette.secondary.main
      : theme.palette.background.dark),
  color: color === "primaryLight" ? 'white' : (accent ? theme.palette.secondary.contrastText : ""),

  '&:hover': {
    backgroundColor: color === "primaryLight" ? theme.palette.translucent.font : theme.palette.button.light,
  },
  

}));

const LargeButtonSecondary = (props) => {


  return (
    <StyledButton
      {...props}
    >
      {props.children}
    </StyledButton>
  );
}

export default LargeButtonSecondary;
