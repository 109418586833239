import { GET_DASHBOARD_OVERVIEW, GET_OVERALL_COMPLETED_COUNT } from "../actions/types";

const initialState = {
    overview_details: {},
    overall_compeleted_count: 0
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_DASHBOARD_OVERVIEW:
            return {
                ...state,
                overview_details: action.payload,
            };
        case GET_OVERALL_COMPLETED_COUNT:
            return {
                ...state,
                overall_completed_count: action.payload,
            };
        default:
            return state;
    }
};
