import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';

if (process.env.REACT_APP_STAGE === 'test') {
  const { worker } = require('./mocks/browser')
  worker.start()
}

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode><App /></React.StrictMode>
    </Provider>,
    document.getElementById('root')
);

if (module.hot) {
    module.hot.accept(); // for webpack HMR
}
