import { GET_PAST_PURCHASES, GET_TRANSACTIONS, GET_SERVICE_PRICES, GET_PRICE_GROUPS, GET_CREDITS, GRADING_SUBMITTED, PAYMENT_SUCCESS } from '../actions/types.js';
import { AUDIT_SERVICE_PRODUCT_CODE, DR_FULL, PRODUCT_CODE_TO_SERVICE_NUM } from '../constants/constants';

const initialState = {
    // unittransactions: [],
    // unitsubscriptions: [],
    // payment_form: [],
    // unitpromotions: [],
    transactions: null,
    pastPurchases: null,
    servicePrices: null,
    priceGroups: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PRICE_GROUPS:
            return {
                ...state,
                priceGroups: action.payload
            };
        case PAYMENT_SUCCESS:
            if (typeof action.payload !== Number) {
                return state;
            }
            return {
                ...state,
                credits: action.payload
            };
        case GET_SERVICE_PRICES:
            let servicePrices = {};
            action.payload.forEach(price => {
                const serviceNum = PRODUCT_CODE_TO_SERVICE_NUM[price.product_code];
                let newPrice = { ...price };
                servicePrices = { ...servicePrices, [serviceNum]: newPrice }
            })
            return {
                ...state,
                servicePrices
            }
        case GET_CREDITS:
            return {
                ...state,
                //credits: action.payload.unit_credits
            }
        case GRADING_SUBMITTED:
            return {
                ...state,
            }
        case GET_PAST_PURCHASES:
            return {
                ...state,
                pastPurchases: action.payload
            }
        case GET_TRANSACTIONS:
            return {
                ...state,
                transactions: action.payload
            }
        default:
            return state;
    }
}
