import React from 'react';
import { BASIC_PERMISSION, BASIC_PERMISSIONS, PREMIUM_PERMISSIONS, STANDARD_PERMISSIONS, TIER_ORDER } from "../constants/subscriptions";
import { AVAILABLE_AWS_REGIONS } from '../containers/chimeApp/constants';
import { convertDateToZonedTime } from './date';
import { TELECONSULT_SYSTEM_TIMEZONE } from '../constants/meetings';
import { zonedTimeToUtc } from 'date-fns-tz';
import {
	AMD_LITE, DR_FULL, DR_LITE, GC_LITE, GLAUCOMA_CDR, LR, QA,
	RETINO_SCAN,
	RETINO_SCAN_ADV,
	RETINO_SCAN_ADV_SERVICES,
	RETINO_SCAN_SERVICES
} from "../constants/constants";
import { calculateWorstCasesByImages, separateLeftRightImages } from "./calculations";
import { LEFT, NA, NO_RESULT, RIGHT } from "../constants/results";
import _ from "lodash";

export const setLocalStorageWithExpire = (key, value, secondsToLive) => {
	const now = new Date();
	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		value: value,
		timeToLive: now.getTime() + secondsToLive * 1000,
	};
	localStorage.setItem(key, JSON.stringify(item));
};

export const getLocalStorageWithExpire = (key) => {
	const itemString = localStorage.getItem(key);
	if (!itemString) {
		return null;
	}
	const item = JSON.parse(itemString);
	const now = new Date();
	if (now.getTime() > item.timeToLive) {
		// If the item is expired, delete the item from storage
		localStorage.removeItem(key);
		return null;
	}
	return item.value;
};

export const sortMeetingByTime = (meetingList, isDescending = false) => {
	return [...meetingList].sort((meeting1, meeting2) => {
		const result = new Date(meeting1?.start_datetime).getTime() - new Date(meeting2?.start_datetime).getTime();
		return isDescending ? -result : result;
	});
};

export const sortMeetingByName = (meetingList, isDescending = false) => {
	return isDescending ? [...meetingList].sort().reverse() : [...meetingList].sort();
};

/**
 * split meetings by specific date time
 * @param {Array} meetings meeting array
 * @param {Date} dateTime The datetime used for splitting. If omitted, use scheduling system date time by default.
 * @returns {Array<Array>} an array with split meetings
 */
export const splitMeetingsByDate = (
	meetings,
	dateTime = convertDateToZonedTime(new Date(), TELECONSULT_SYSTEM_TIMEZONE, Intl.DateTimeFormat().resolvedOptions().timeZone)
) => {
	// [upcomingMeetings, historyMeetings]
	return meetings.reduce(
		(result, meeting) => {
			// console.log(new Date(meeting?.start_datetime), dateTime)
			result[
				zonedTimeToUtc(meeting.start_datetime, TELECONSULT_SYSTEM_TIMEZONE) >= dateTime && meeting?.status === 'confirmed'
					? 0
					: 1
			].push(meeting); // Determine and push to small/large arr
			return result;
		},
		[[], []]
	); // Default upcoming/history meeting arrays are empty
};

export async function getNearestMediaRegion() {
	let nearestMediaRegion = '';
	const defaultMediaRegion = AVAILABLE_AWS_REGIONS['ap-southeast-2'];
	try {
		const nearestMediaRegionResponse = await fetch(`https://nearest-media-region.l.chime.aws`, {
			method: 'GET',
		});
		const nearestMediaRegionJSON = await nearestMediaRegionResponse.json();
		//console.log(nearestMediaRegionJSON.region);
		nearestMediaRegion = nearestMediaRegionJSON.region;
	} catch (error) {
		nearestMediaRegion = defaultMediaRegion;
		//console.log('Default media region ' + defaultMediaRegion + ' selected: ' + error.message);
	} finally {
		return nearestMediaRegion;
	}
}

export const checkTeleconsultProviderExists = (email, providerList=[]) => {
	return providerList.some(provider => provider.email === email)
}

/**
 * conditionally wrap an element
 * @param {boolean} condition the condition to determine whether to wrap the element
 * @param {boolean} visible the condition to determine whether the element is visible
 * @param {ReactElement || DetailedReactHTMLElement || JSX.Element}  wrapper ConditionalWrapper component
 * @param {ReactElement || String} children the element to be wrapped
 * @returns {ReactElement || DetailedReactHTMLElement} wrapped children if condition is true otherwise return the children itself
 */
export const ConditionalWrapper = ({ condition, visible=true, wrapper, children}) => {
	if (!visible) {
		return null
	}
	if (condition) {
		return React.cloneElement(wrapper, null, children);
	}
	return children;
};

export const allotObjectToTier = (planName, basicObj, standardObj, premiumObj) => {

   if (planName?.includes("Basic")) {
		return basicObj;
	} else if (planName?.includes("Standard")) {
		return standardObj;
	} else if (planName?.includes("Premium")) {
		return premiumObj;
	} else {
		// TODO error
	}
}

export const dictCheck = (dict, key) => {
	if (key in dict) {
		return dict[key];
	} else {
		return null;
	}
};

export const determineToolTipTitleContent = (requiredPermissions) => {
	let body = "Upgrade your subscription if you wish to select this service";
	if (requiredPermissions.every(permission => BASIC_PERMISSIONS.indexOf(permission) > -1)) {
		return ["Basic Plan required!", body]
	}
	else if (requiredPermissions.every(permission => STANDARD_PERMISSIONS.indexOf(permission) > -1)) {
		return ["Standard Plan required!", body]
	}
	// else if (requiredPermissions.every(permission => (BASIC_PLUS_PERMISSIONS && STANDARD_PLUS_PERMISSIONS).indexOf(permission) > -1)) {
	//     return ["Plus or Premium Plan required!", body]
	// }
	else if (requiredPermissions.every(permission => PREMIUM_PERMISSIONS.indexOf(permission) > -1)) {
		return ["Premium Plan required!", body]
	} else {
		return null
	}
}


export const normalizePlanName = (name) => {
	let normalizedName = "";
	if (name) {
		if (name.includes("Basic")) {
			normalizedName = "Basic";
		} else if (name.includes("Standard")) {
			normalizedName = "Standard";
		} else if (name.includes("Premium")) {
			normalizedName = "Premium";
		} else {
			return "Not-Supported";
		}

		return normalizedName;
	}
};

export const getIndexInTierOrder = (planName) => {
	return TIER_ORDER.indexOf(planName);
};

/**
 * categorize service results into retinoScan and retinoScan advanced
 * @param {array} serviceResults the results to categorize
 * @returns {array[[][]]} an array contains retinoScan and retinoScan advanced results
 */
export const categorizeServiceResults = (serviceResults = []) => {
	return serviceResults.reduce(
		(serviceResults, result) => {
			if (RETINO_SCAN_SERVICES.includes(result.service_type)) {
				const serviceIndex = RETINO_SCAN_SERVICES.indexOf(result.service_type);
				// sort retinoScan services in GC_LITE GC_LITE AMD order
				serviceResults[RETINO_SCAN][serviceIndex] = result;
			} else if (RETINO_SCAN_ADV_SERVICES.includes(result.service_type)) {
				const serviceIndex = RETINO_SCAN_ADV_SERVICES.indexOf(result.service_type);
				// sort retinoScan services in GLAUCOMA_CDR and DR FULL
				serviceResults[RETINO_SCAN_ADV][serviceIndex] = result;
			}
			return serviceResults;
		},
		[[], []]
	);
};

/**
 * process images to get formatted service results
 * @param {array} examEyeImages the images with service results
 * @returns {array} an array contains formatted service results
 */
export const getServiceResultsByImages = (examEyeImages) => {
	if (_.isEmpty(examEyeImages)) return [];
	const leftRightIndexMap = { 0: LEFT, 1: RIGHT };

	const separatedLeftRightImages = separateLeftRightImages(examEyeImages);
	const separatedWorstImageGradingServices = Object.values(separatedLeftRightImages).map((leftOrRightImages) =>
		calculateWorstCasesByImages(leftOrRightImages)
	);
	let serviceResults = separatedWorstImageGradingServices.reduce(
		(serviceResults, worstImageGradingServices, currentIndex) => {
			worstImageGradingServices.map((imageGradingService) => {
				let { id, service_type, ...results } = imageGradingService;
				let serviceResult = serviceResults.find((serviceResult) => serviceResult.service_type === service_type);
				if (serviceResult) {
					serviceResult.laterality.push({
						laterality: leftRightIndexMap[currentIndex],
						...results,
					});
				} else {
					serviceResult = {
						service_type: imageGradingService.service_type,
						laterality: [
							{
								laterality: leftRightIndexMap[currentIndex],
								...results,
							},
						],
					};
					serviceResults.push(serviceResult);
				}
			});
			return serviceResults;
		},
		[]
	);
	return serviceResults.filter((serviceResult) =>
			[DR_LITE, DR_FULL, AMD_LITE, GC_LITE, GLAUCOMA_CDR].includes(serviceResult.service_type)
			// && !serviceResult.laterality?.find(lateralResult => lateralResult.result === NA)
	);
};

export const toTrunc = (number, precision = 2) => {
	let regex = RegExp(`^\\d+(?:\\.\\d{0,${precision}})?`)
	if (typeof number === 'number') {
	return number.toString().match(regex)?.[0];
	}
};

/* Check if string is valid UUID */
export const checkIfValidUUID = (str) => {
	// Regular expression to check if string is a valid UUID
	const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
	return regexExp.test(str);
}
