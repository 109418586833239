import React from "react";
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import LargeButton from "../atoms/LargeButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import IconHeading from "../molecules/IconHeading";
import LargeButtonSecondary from "../atoms/LargeButtonSecondary";
const PREFIX = 'AlertDialog';

const classes = {
  form: `${PREFIX}-form`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.form}`]: {
    margin: theme.spacing(5),
  }
}));

const AlertDialog = (props) => {


  const handleClose = (event, reason) => {
    if (props.disableBackdropClick && reason === "backdropClick") {
      return false;
    }

    if (props.disableEscapeKeyDown && reason === "escapeKeyDown") {
      return false;
    }

    if (typeof props.onCancel === "function") {
      props.onCancel();
    }
  }

  return (
    <Root>
      <Dialog
        open={props.isOpen}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={props.isFullScreen}
      >
        <DialogTitle id="alert-dialog-title">
          <IconHeading title={props.title} icon={props.icon} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ maxWidth: "360px"}}>
            {props.description}
          </DialogContentText>
          <DialogContentText id="alert-dialog-description" style={{ maxWidth: "350px", textAlign: "center"}}>
            {props.description2}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="center">
            <Grid item>
              {props.cancelLabel ? <LargeButtonSecondary onClick={props.isAuditExamPage? props.onConfirm : props.onCancel} color="primary" autoFocus>
                {props.isAuditExamPage ? props.confirmLabel : props.cancelLabel}
              </LargeButtonSecondary> : null}
            </Grid>
            <Grid item>
              <LargeButton onClick={props.isAuditExamPage? props.onCancel : props.onConfirm} color={props.confirmButtonColor || 'blue'}>
                {props.isAuditExamPage ? props.cancelLabel : props.confirmLabel}
              </LargeButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Root>
  );
};
AlertDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  disableBackdropClick: PropTypes.bool,
  isFullScreen: PropTypes.string,
  icon: PropTypes.object,
  isAuditExamPage: PropTypes.bool,
  confirmButtonColor: PropTypes.string,
};
export default AlertDialog;