import {
	BOOK_NEW_MEETING,
	CANCEL_MEETING,
	EDIT_CLIENT_INFO,
	GET_AVAILABLE_SLOTS,
	GET_CLIENT_INFO,
	GET_FIRST_AVAILABLE_SLOT,
	GET_MEETING_DETAILS,
	GET_MEETING_HOSTS,
	GET_MEETINGS,
	GET_RESCHEDULE_MEETING_DETAILS,
	GET_SCHEDULE,
	GET_UPCOMING_MEETINGS,
	JOIN_MEETING,
	SELECT_MEETING_HOST,
	SELECT_MEETING_TIME, SET_EXAM_FOR_MEETING, SET_SB_CLIENT_ID, SET_TIMEZONE,
} from '../actions/types.js';
import { AVAILABLE_MEETING_REGIONS } from "../constants/meetings";

const initialState = {
	meetings: [],
	upcomingMeetings: [],
	meetingHosts: [],
	firstAvailableSlots: {},
	timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, //current system/browser time zone
	meetingServiceRegion: AVAILABLE_MEETING_REGIONS[process.env.REACT_APP_AWS_REGION || 'ap-southeast-1']
};

export default function meetingsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_MEETING_HOSTS:
			return {
				...state,
				meetingHosts: action.payload,
			};
		case SELECT_MEETING_HOST:
			return {
				...state,
				meetingHost: action.payload,
			};
		case GET_CLIENT_INFO:
			return {
				...state,
				clientInfo: action.payload,
			};
		case EDIT_CLIENT_INFO:
			return {
				...state,
				clientInfo: action.payload,
			};
		case SELECT_MEETING_TIME:
			return {
				...state,
				meetingTime: action.payload,
			};
		case GET_MEETINGS:
			return {
				...state,
				meetings: action.payload,
			};
		case GET_UPCOMING_MEETINGS:
			return {
				...state,
				upcomingMeetings: action.payload,
			};
		case GET_AVAILABLE_SLOTS:
			return {
				...state,
				availableSlots: action.payload,
			};
		case GET_FIRST_AVAILABLE_SLOT:
			return {
				...state,
				firstAvailableSlots: { ...state.firstAvailableSlots, ...action.payload },
			};
		case GET_SCHEDULE:
			return {
				...state,
				hostSchedule: action.payload,
			};
		case BOOK_NEW_MEETING:
			return {
				...state,
				newBooking: action.payload,
			};
		case CANCEL_MEETING:
			return {
				...state,
				upcomingMeetings: state.meetings.filter((meeting) => meeting.id !== action.payload),
			};
		case GET_MEETING_DETAILS:
			return {
				...state,
				meetingDetails: action.payload,
			};
		case GET_RESCHEDULE_MEETING_DETAILS:
			return {
				...state,
				meetingToReschedule: action.payload,
			};
		case JOIN_MEETING:
			return {
				...state,
				chimeMeetingJoinDetails: action.payload,
			};
		case SET_EXAM_FOR_MEETING:
			return {
				...state,
				examToConsult: action.payload
			};
		case SET_TIMEZONE:
			return {
				...state,
				timezone: action.payload
			}
		case SET_SB_CLIENT_ID:
			return {
				...state,
				simplyBookClientID: action.payload
			}
		default:
			return state;
	}
}
