export const getBackendURL = (isImage = false) => {
    // development or testing
    if (!process.env.REACT_APP_STAGE || ['development', 'test'].includes(process.env.REACT_APP_STAGE))
        return `http://${window.location.hostname}:8000`;

    // staging or produciton
    else {
        if (isImage)
            return '';
        return `https://api.${window.location.host}`;
    }

}

export const getHost = () => {
    // development or testing
    if (!process.env.REACT_APP_STAGE || ['development', 'test'].includes(process.env.REACT_APP_STAGE))
        return `${window.location.hostname}:8000`;

    // staging or production
    else
        return `api.${window.location.host}`;
}
