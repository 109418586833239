import {
  DISPLAY_COMPLETED_AUDITS,
  DISPLAY_PENDING_AUDITS,
  DISPLAY_POOLED_AUDITS,
  DISPLAY_ASSIGNED_AUDITS,
  GET_AUDIT_DETAILS,
  AUDIT_ASSIGN,
  AUDIT_UNASSIGN,
  WS_AUDIT_STATUS_CHANGE,
  SHOULD_REFRESH_POOLED_AUDIT_EXAMS,
  TOTAL_POOLED_AUDIT_COUNT,
  AUDIT_SUBMITTED,
  CONNECT_REVIEWING_AUDIT_WEBSOCKET, DISCONNECT_REVIEWING_AUDIT_WEBSOCKET
} from "../actions/types.js";
import _ from "lodash";

const initialState = {
  available_audits: null,
  assignedAudits: null,
  pooledAudits: null,
  completedAudits: null,
  reviewingWSConnectedAudits: [],
};

export default function auditFunction(state = initialState, action) {
  switch (action.type) {
    case DISPLAY_PENDING_AUDITS:
      return {
        ...state,
        available_audits: action.payload,
      };
    case DISPLAY_ASSIGNED_AUDITS:
      return {
        ...state,
        assignedAudits: action.payload,
      };
    case DISPLAY_POOLED_AUDITS:
      return {
        ...state,
        pooledAudits: action.payload,
      };
    case DISPLAY_COMPLETED_AUDITS:
      return {
        ...state,
        completedAudits: action.payload,
      };

    case GET_AUDIT_DETAILS:
      return {
        ...state,
        audit_details: action.payload,
      };
    case AUDIT_ASSIGN:
      return {
        ...state,
      };
    case AUDIT_UNASSIGN:
      return {
        ...state,
      }
    case WS_AUDIT_STATUS_CHANGE:
      const auditId = action.payload.audit_id;
      let pooledAudits = state.pooledAudits;
      let newPooledAudits = [];
      !_.isEmpty(pooledAudits) && pooledAudits.forEach((audit, index) => {
        if ( audit.id === auditId ) {
          const newAudit = { ...audit, ...action.payload };
          if ( !action.payload.is_completed ) {
            newPooledAudits.push(newAudit);
          }
        } else {
          newPooledAudits.push(audit);
        }
      });
      return {
        ...state,
        pooledAudits: newPooledAudits
      };

    case TOTAL_POOLED_AUDIT_COUNT:
      return {
        ...state,
        totalPooledAuditCount: action.payload
      }
    case AUDIT_SUBMITTED:
      return {
        ...state,
        audit_details: {}
      }
    case CONNECT_REVIEWING_AUDIT_WEBSOCKET:
        return {
            ...state,
          reviewingWSConnectedAudits: [...action.payload]
        }
    case DISCONNECT_REVIEWING_AUDIT_WEBSOCKET:
        return {
            ...state,
            reviewingWSConnectedAudits: [...action.payload]
        }
    default:
      return state;
  }
}
