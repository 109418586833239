class logger {

	static log(...logItems) {
		if (['test', 'development', 'staging'].includes(process.env.REACT_APP_STAGE)) {
			console.log(
				`%clog---`,
				`color: #008f68; `,
				...logItems,
			);
		}
	}

	static warn(...logItems) {
		if (['test', 'development', 'staging'].includes(process.env.REACT_APP_STAGE)) {
			console.log(
				`%cwarn---`,
				`color: #ffc502; `,
				...logItems,
			);
		}
	}

	static formattedLog(...logItems) {
		const formattedLogItems = logItems.map((item)=> typeof item === 'object' ? JSON.stringify(item, null, 1): item)
		if (['test', 'development', 'staging'].includes(process.env.REACT_APP_STAGE)) {
			console.log(
				`%cformat flog---`,
				`color: #008f68; `,
				...formattedLogItems,
			);
		}
	}

	static debug(...logItems) {
		if (['test', 'development', 'staging'].includes(process.env.REACT_APP_STAGE)) {
			console.debug(
				`%cdebug---`,
				`color: #008f68; `,
				...logItems,
			);
		}
	}

	static table(...logItems) {
		if (['test', 'development', 'staging'].includes(process.env.REACT_APP_STAGE)) {
			console.table(
				...logItems,
			);
		}
	}
}

export default logger;
