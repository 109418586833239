import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import IconLabel from '../atoms/IconLabel';
import { Grid, Typography } from '@mui/material';

const StyledGrid = styled(Grid)((
    {
        theme, noMargin
    }
) => ({
        margin: noMargin ? 0 : theme.spacing(2, 0),
}));

const StyledTypography = styled(Typography)((
    {
        theme, smallTitle
    }
) => ({
        margin: smallTitle ? theme.spacing(0, 0.3): theme.spacing(0, 1.5),
}));


const IconHeading = (props) => {


    return (
        <StyledGrid container justifyContent="flex-start" alignItems="center">
            <Grid item>
                <IconLabel icon={props.icon} />
            </Grid>
            <Grid item>
                <StyledTypography component="h3" variant="h3" >
                    {props.title}
                </StyledTypography>
            </Grid>
        </StyledGrid>
    );

}

IconHeading.propTypes = {
    icon: PropTypes.element,
    title: PropTypes.string,
    noMargin: PropTypes.bool,
}

export default IconHeading;