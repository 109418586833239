import {
	PATIENTLOOKUP_CLOSE,
	PATIENTLOOKUP_OPEN,
	FILTER_OPEN,
	FILTER_CLOSE,
	SNACKBAR_CLOSE,
	SET_SNACKBAR_WARNING,
	SET_SNACKBAR_ERROR,
	SET_SNACKBAR_SUCCESS,
	SET_SNACKBAR_INFO,
	IMAGESVIEW_CLOSE,
	IMAGESVIEW_OPEN,
	ADDSERVICES_CLOSE,
	ADDSERVICES_OPEN,
	MODAL_OPEN,
	MODAL_CLOSE,
	OPEN_TERMS_DIALOG,
	CLOSE_TERMS_DIALOG,
	SET_UNAGREED_TERMS_BY_USER_ROLE,
	SET_COUNTRY,
	SET_GOOGLE_PLACES,
	SET_EMAIL_LIST,
	COLLAPSE_NAV_BAR_LEFT,
	SET_CURRENT_SCREEN
} from "./types";

export const handlePatientLookUpClose = () => (dispatch) => {
	dispatch({
		type: PATIENTLOOKUP_CLOSE,
	});
};

export const handleFilterTableClose = () => (dispatch) => {
	dispatch({
		type: FILTER_CLOSE,
	});
};

// For the Add Risk Prediction Service close pop up
export const handleAddServicesClose = () => (dispatch) => {
	dispatch({
		type: ADDSERVICES_CLOSE,
	});
};

// For the Add Risk Prediction Service open pop up
export const handleAddServicesOpen = () => (dispatch) => {
	dispatch({
		type: ADDSERVICES_OPEN,
	});
};

export const handlePatientLookUpOpen = () => (dispatch) => {
	dispatch({
		type: PATIENTLOOKUP_OPEN,
	});
};

export const handleFilterTableOpen = () => (dispatch) => {
	dispatch({
		type: FILTER_OPEN,
	});
};

export const handleModalOpen = (modal) => (dispatch) => {
	dispatch({
		type: MODAL_OPEN,
		payload: modal,
	});
};

export const handleModalClose = (modal) => (dispatch) => {
	dispatch({
		type: MODAL_CLOSE,
		payload: modal,
	});
};

export const handleImagesViewClose = () => (dispatch) => {
	dispatch({
		type: IMAGESVIEW_CLOSE,
	});
};

export const handleImagesViewOpen = (image) => (dispatch) => {
	dispatch({
		type: IMAGESVIEW_OPEN,
		payload: image,
	});
};

export const closeSnackbar = () => (dispatch) => {
	dispatch({
		type: SNACKBAR_CLOSE,
	});
};

export const setSnackbar = (message, type) => (dispatch) => {
	switch (type) {
		case "success":
			dispatch({
				type: SET_SNACKBAR_SUCCESS,
				payload: message,
			});
			break;
		case "info":
			dispatch({
				type: SET_SNACKBAR_INFO,
				payload: message,
			});
			break;
		case "warning":
			dispatch({
				type: SET_SNACKBAR_WARNING,
				payload: message,
			});
			break;
		case "error":
			dispatch({
				type: SET_SNACKBAR_ERROR,
				payload: message,
			});
			break;
		default:
			return null;
	}
};

export const setTermsDialog = (isOpen) => (dispatch) => {
	dispatch({
		type: isOpen ? OPEN_TERMS_DIALOG : CLOSE_TERMS_DIALOG,
	});
};


export const setUnagreedTermsByRole = (terms) => (dispatch) => {
	dispatch({
		type: SET_UNAGREED_TERMS_BY_USER_ROLE,
		payload: terms,
	});
};

export const setGooglePlaces = (google_address) => (dispatch) => {
				dispatch({
					type: SET_GOOGLE_PLACES,
					payload: google_address,
				});
	};

export const setCountry = (country) => (dispatch) => {
	dispatch({
		type: SET_COUNTRY,
		payload: country,
	})
};

export const setInviteEmailsList = (email_list) => (dispatch) => {
	dispatch({
		type: SET_EMAIL_LIST,
		payload: email_list,
	})
};

export const collapseNavBarLeft = (nav_bar_left_collapse) => (dispatch) => {
	dispatch({
		type: COLLAPSE_NAV_BAR_LEFT,
		payload: nav_bar_left_collapse,
	})
	
};

export const setCurrentScreen = (current_screen) => (dispatch) => {
	dispatch({
		type: SET_CURRENT_SCREEN,
		payload: current_screen,
	})
	
};