import React, { lazy, Suspense } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Snackbar, Alert, StyledEngineProvider, CssBaseline } from "@mui/material";
import Loading from "./components/layout/organisms/Loading/Loading";
import theme from "./styles/theme";
import { acceptTermsOfUse, loadUser, } from "./actions/auth";
import { closeSnackbar, setTermsDialog, setUnagreedTermsByRole, } from "./actions/controls";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./components/routes/PrivateRoute";
import { connect } from "react-redux";

import store from "./store";
import AlertDialog from "./components/layout/templates/AlertDialog";
import { FileTextIcon } from "./resources/icons";

const NewUserActivation = lazy(() =>
    import("./containers/accounts/auth/NewUserActivation")
);
const NewUserSetPassword = lazy(() =>
    import("./containers/accounts/auth/NewUserSetPassword")
);
const ForgotPassword = lazy(() =>
    import("./containers/accounts/auth/ForgotPassword")
);
const ForgotPasswordConfirm = lazy(() =>
    import("./containers/accounts/auth/ForgotPasswordConfirm")
);
const PageNotFound = lazy(() =>
    import("./components/layout/organisms/PageNotFound")
);
const Dashboard = lazy(() => import("./containers/main/Dashboard"));
const Login = lazy(() => import("./containers/accounts/auth/Login"));
const SignUp = lazy(() => import("./containers/accounts/auth/SignUp"));
const RoleSelectionScreen = lazy(() => import("./containers/accounts/auth/RoleSelectionScreen"));
const ChimeApp = lazy(() => import("./containers/chimeApp/app"))
const AcceptInvitation = lazy(() => import("./containers/referrals/AcceptInvitation"));

const App = (props) => {

    const { controls, acceptTermsOfUse, setTermsDialog, setUnagreedTermsByRole } = props;

    React.useEffect(() => {
        props.loadUser();
    }, [])

    const alertDialogTermsOfUse = () => {
        const unagreedTermsByUserRole = controls.unagreedTermsByUserRole;
        const openTermsDialog = controls.openTermsDialog;
        return (
            <AlertDialog
                disableBackdropClick
                isOpen={openTermsDialog}
                icon={<FileTextIcon />}
                onConfirm={() => {
                    acceptTermsOfUse({ ids: [unagreedTermsByUserRole[0].id] })
                    setTermsDialog(false);
                    setUnagreedTermsByRole(null);
                }}
                confirmLabel="Confirm" //wanted to use primary button
                title="We've Updated Our Terms"
                description="In order to continue using TeleEye MD, you will need to agree with our latest Terms of Use."
                description2={
                    <a
                        target="_blank"
                        href={
                            unagreedTermsByUserRole?.length > 0 ? unagreedTermsByUserRole[0].text : " "
                        }
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
                    >
                        Terms of Use
                    </a>
                }
            />
        );
    }

    const handleSnackbarClose = () => {
        store.dispatch(closeSnackbar());
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Router>
                    <CssBaseline />
                    <Suspense fallback={<Loading isFullScreen />}>
                        <Switch>
                            <Route
                                path="/invitation_via_referral/"
                                component={AcceptInvitation}
                            />
                            <Route
                                path={"/login/"}
                                component={Login} />
                            <Route
                                path={"/register/role/"}
                                component={RoleSelectionScreen}
                            />
                            <Route
                                path={"/register/form/"}
                                component={SignUp}
                            />
                            {/* <Route path={"/register/"} component={SignUp} /> */}
                            <Route
                                path={"/accounts/activate/set_password/:uid/:token/"}
                                component={NewUserSetPassword}
                            />
                            <Route
                                path={"/accounts/activate/:uid/:token/"}
                                component={NewUserActivation}
                            />
                            <Route
                                path={"/forgot_password/"}
                                component={ForgotPassword}
                            />
                            <Route
                                path={"/accounts/forgot_password/:uid/:token/"}
                                component={ForgotPasswordConfirm}
                            />
                            <PrivateRoute
                                path="/meeting"
                                component={ChimeApp}
                            />
                            <PrivateRoute path={"/"} component={Dashboard} />
                            <Route path="*" component={PageNotFound} />
                        </Switch>
                    </Suspense>
                </Router>
                <Snackbar
                    sx={{'& .MuiAlert-root': {color: 'white'}}}
                    open={controls.snackBarOpen}
                    autoHideDuration={5000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                >
                    <Alert
                        elevation={6}
                        variant="filled"
                        severity={controls.type}
                        onClose={handleSnackbarClose}
                    >
                        {controls.message}
                    </Alert>
                </Snackbar>
                {alertDialogTermsOfUse()}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

const mapStateToProps = (state) => ({
    controls: state.controls,
});

export default connect(mapStateToProps, {
    loadUser,
    acceptTermsOfUse,
    setTermsDialog,
    setUnagreedTermsByRole,
})(App);