// Error Results
export const NA = -1;
export const NO_RESULT = 1;
export const UNABLE_TO_GRADE = 2;

// QA Results
export const PASSED = 2;
export const FAILED = 3;

// LR Results
export const LEFT = 2;
export const RIGHT = 3;

// General Results
export const NORMAL = 3
export const SUSPECT = 4;

// DR Full Results
export const MILD = 4;
export const MODERATE = 5;
export const SEVERE = 6;
export const PDR = 7;

//AMD and AMD DR Results
export const DR_DISEASE = 3;
export const AMD_DISEASE = 4;
export const AMD_DR_DISEASE = 5;
export const AMD_HP = 98;
export const AMD_DR_HP = 99;

// --------------- STRING ---------------------
// General Results String
export const NORMAL_STR = "Normal";
export const SUSPECT_STR = "Suspect";
export const REF_REPORT_STR = "REFER TO REPORT"
export const GRADING_STR = "AI Grading..."
//  Error Results string
export const UNABLE_TO_GRADE_STR = "Unable to Grade";
export const NO_RESULT_STR = "No Result";
export const NA_STR = 'Not Applicable';
//AMD DR Results
export const AMD_DR_DISEASE_STR = "Suspect DR Disease";
export const AMD_DISEASE_STR = "Suspect AMD Disease";
export const AMD_HP_STR = "Suspect AMD disease (High probability AMD, some AMD signs)";
export const AMD_DR_HP_STR = "Suspect DR disease (High probability, some DR Signs)";
export const CLICK_TO_UPDATE_STR = "Click to update";
// DR Results String
export const MILD_STR = "Mild NPDR";
export const MODERATE_STR = "Moderate NPDR";
export const SEVERE_STR = "Severe NPDR";
export const PDR_STR = "PDR";
// ----------------- END STRING ---------------

export const DME_NO_SUSPECT = 3;
export const DME_SUSPECT = 4;

export const GLAUCOMA_NO_SUSPECT = 3;
export const GLAUCOMA_SUSPECT = 4;

export const GC_LITE_NO_SUSPECT = 3;
export const GC_LITE_SUSPECT = 4;

export const AMD_NO_APPARENT = 3;
export const AMD_NON_SIGNIFICANT = 4;
export const AMD_SIGNIFICANT = 5;

export const MEDIA_OPACITY_NONE = 3;
export const MEDIA_OPACITY_NON_SIGNIFICANT = 4;
export const MEDIA_OPACITY_SIGNIFICANT = 5;

export const RESULT_PENDING = 1;
export const RESULT_ASSIGNED = 2;
export const RESULT_COMPLETED = 3;

// ----------------- URGENCY LEVEL ---------------
export const URGENCY_LEVEL_ENUMS = {
    NOT_URGENT: 0,
    URGENT: 1,
    RESOLVED: 2
};

export const URGENCY_LEVEL_STR_ENUMS = {
    [URGENCY_LEVEL_ENUMS.NOT_URGENT]: 'Not Urgent',
    [URGENCY_LEVEL_ENUMS.URGENT]: 'Urgent',
    [URGENCY_LEVEL_ENUMS.RESOLVED]: 'Resolved',
};
// ------------------------ STRING -------------------

export const DME_RESULTS_STR_ENUMS = {
    [null]: NO_RESULT_STR,
    [UNABLE_TO_GRADE]: UNABLE_TO_GRADE_STR,
    [DME_NO_SUSPECT]: 'No DME Suspect',
    [DME_SUSPECT]: 'Suspected DME',
}

export const GLAUCOMA_RESULTS_STR_ENUMS = {
    [GLAUCOMA_NO_SUSPECT]: 'No Glaucoma Suspect',
    [GLAUCOMA_SUSPECT]: 'Suspected Glaucoma',
}

export const GC_LITE_RESULTS_STR_ENUMS = {
    [GC_LITE_NO_SUSPECT]: 'No Glaucoma Suspect',
    [GC_LITE_SUSPECT]: 'Suspected Glaucoma',
}

export const AMD_RESULTS_STR_ENUMS = {
    [AMD_NO_APPARENT]: 'No Apparent AMD',
    [AMD_NON_SIGNIFICANT]: 'Non-Significant',
    [AMD_SIGNIFICANT]: 'Significant',
}

export const MEDIA_OPACITY_RESULTS_STR_ENUMS = {
    [null]: NO_RESULT_STR,
    [UNABLE_TO_GRADE]: UNABLE_TO_GRADE_STR,
    [MEDIA_OPACITY_NONE]: NORMAL_STR,
    [MEDIA_OPACITY_NON_SIGNIFICANT]: 'Non-Significant',
    [MEDIA_OPACITY_SIGNIFICANT]: 'Significant',
}

export const DR_FULL_RESULTS_STR_ENUMS = {
    [UNABLE_TO_GRADE]: UNABLE_TO_GRADE_STR,
    [NORMAL]: NORMAL_STR,
    [SUSPECT]: SUSPECT_STR,
    [MILD_STR]: "Mild NPDR",
    [MODERATE_STR]: "Moderate NPDR",
    [SEVERE_STR]: "Severe NPDR",
    [PDR_STR]: "PDR",
}

export const LR_STR_ENUMS = {[LEFT]: 'Left', [RIGHT]: 'Right'}
export const STR_LR_ENUMS = {left: LEFT, right: RIGHT, Left: LEFT, Right: RIGHT, LEFT: LEFT, RIGHT: RIGHT, leftEyeResult: LEFT, rightEyeResult: RIGHT}